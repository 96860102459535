@use 'ht-styleguide/build/variables';

.home_container {
  width: 100%;
  height: 100vh;
  background-color: variables.$ht-teal-500;
  color: variables.$ht-white;
  padding: 20px;
}
.home_container_logo {
  padding: 30px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  i {
    font-size: 27px;
  }
}

.home_title {
  font-size: 14px;
  line-height: 18.3px;
  letter-spacing: 1.31px;
}

.home_container_form {
  color: variables.$ht-black;
  padding: 30px;

  div {
    margin-bottom: 20px;
  }
}
.home_form_margin_bottom {
  margin-bottom: 15px;
}
